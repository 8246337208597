import "./footerbar.css";
import { Link, useNavigate } from "react-router-dom";
import { socialData } from "../../constants/socialData";
import { menuData } from "../../constants/menuData";
import { useSelector } from "react-redux";
import { FaBarcode } from "react-icons/fa";
import { useState } from "react";

const Footerbar = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const [id, setId] = useState("");

  const validateCertificateID = (evt) => {
    evt.preventDefault();
    if (id) {
      navigate(`/verify/${id}`);
    }
    setId("");
  };

  return (
    <footer>
      <div className="footer-box">
        <div className="socials">
          {socialData.map((item, idx) => (
            <Link
              to={item.link}
              key={idx}
              target="_blank"
              rel="noreferrer"
              className="foot-link"
            >
              {item.icon}
            </Link>
          ))}
        </div>

        <div className="validator">
          <h3>Certificate Validator:</h3>
          <form className="auth-form" onSubmit={validateCertificateID}>
            <div className="input-group">
              <label htmlFor="certID">
                <FaBarcode />
              </label>
              <input
                type="text"
                id="certID"
                value={id}
                required
                onChange={(e) => setId(e.target.value)}
                placeholder="Certificate ID..."
              />
            </div>
            <button className="primary-btn" type="submit">
              Validate
            </button>
          </form>
        </div>

        <hr />

        <div className="links">
          {!auth && (
            <>
              <Link to="/register">Sign Up</Link>
              <Link to="/login">Log In</Link>
            </>
          )}
          {menuData
            .filter((item) =>
              auth && auth.isAdmin
                ? item
                : auth
                ? item.access === "private" || item.access === "public"
                : item.access === "public"
            )
            .map((item, idx) => (
              <Link to={item.path} key={idx}>
                {item.title}
              </Link>
            ))}
        </div>

        <div>
          &copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}, All
          Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footerbar;

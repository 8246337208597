import { useEffect, useState } from "react";
import "./contact.css";
import { useDispatch, useSelector } from "react-redux";
import { FaEnvelope, FaPhoneAlt, FaQuoteLeft, FaUser } from "react-icons/fa";
import { contactRequest } from "../../app/features/user/userSlice";

const Contact = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.users);
  const [userMessage, setUserMessage] = useState({
    fullname: "",
    email: "",
    phone: "",
    text: "",
    id: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth) {
      setUserMessage({
        id: auth._id,
        fullname: `${auth.firstname} ${auth.lastname}`,
        email: auth.email,
        phone:
          auth.callcode && auth.phone ? `${auth.callcode}${auth.phone}` : "",
      });
    }
  }, [auth]);

  const handleSubmitMessage = (e) => {
    e.preventDefault();
    dispatch(contactRequest(userMessage));
  };

  const handleNewMessage = (e) => {
    setUserMessage((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="container">
      <h1>Contact us</h1>
      <div className="contact-us">
        <form className="auth-form" onSubmit={handleSubmitMessage}>
          <p>
            Thank you for considering {process.env.REACT_APP_NAME} eLearning. We
            value your interest and are here to assist you. Whether you have
            questions about our courses, need technical support, or want to
            explore partnership opportunities, our dedicated team is ready to
            help.
          </p>
          <p>
            Feel free to reach out to us, and we'll get back to you as soon as
            possible. Your feedback and inquiries are important to us as we
            strive to provide an exceptional eLearning experience.
          </p>
          <p>
            For a quick and efficient response, we invite you to use our
            convenient contact form below. Simply fill in the required details,
            and our support team will get back to you promptly.
          </p>
          {message && <div className="message">{message}</div>}
          <div className="input-group">
            <label htmlFor="fullname">
              <FaUser />
            </label>
            <input
              id="fullname"
              name="fullname"
              type="text"
              required
              disabled={auth ? true : false}
              value={userMessage.fullname}
              placeholder="Add your full name..."
              onChange={handleNewMessage}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">
              <FaEnvelope />
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              disabled={auth ? true : false}
              value={userMessage.email}
              placeholder="Add your e-mail address..."
              onChange={handleNewMessage}
            />
          </div>
          <div className="input-group">
            <label htmlFor="phone">
              <FaPhoneAlt />
            </label>
            <input
              id="phone"
              name="phone"
              type="phone"
              disabled={auth && auth.callcode && auth.phone ? true : false}
              value={userMessage.phone}
              placeholder="Add your phone number..."
              onChange={handleNewMessage}
            />
          </div>
          <div className="input-group">
            <label htmlFor="text">
              <FaQuoteLeft />
            </label>
            <textarea
              id="text"
              name="text"
              placeholder="Write your message here..."
              rows="5"
              required
              value={userMessage.text}
              onChange={handleNewMessage}
            ></textarea>
          </div>
          <button type="submit" className="btn">
            Send message
          </button>
        </form>

        <div className="contact-info"></div>
      </div>
    </div>
  );
};

export default Contact;
